import { DelegatedDocument } from 'api/documents.types';
import { ShortUser } from 'api/users.types';

export enum DocumentStatus {
  total = 'total',
  pending = 'pending',
  uploaded = 'uploaded',
  processing = 'processing',
  needReplacement = 'need_replacement',
  needInfo = 'need_info',
  done = 'done',
  failed = 'failed',
  deleted = 'deleted',
  rejected = 'rejected',
  merged = 'merged',
  split = 'split',
  pendingApproval = 'pending_approval',
  delegateApproval = 'delegated'
}

export interface APIDocument {
  id: number;
  title: string;
  folder_files_count: number;
  modified_at: string;
  created_at: string;
  file_type: DocumentFileType;
  file: string;
  pdf_file: string;
  state: DocumentStatus;
  comments: { [key: string]: DocumentComment[] };
  size: number;
  sent_at: string;
  deleted_at: string;
  additional_info: {
    data: { filename: string; operation_id: string }[];
  };
  user?: number;
  directory: Directory & { client_directory: Directory };
  delegated_approvals?: DelegatedDocument[];
  type?: string;
  original_file?: string;
  remark?: string;
}

export interface DocumentComment {
  id: number;
  thread_id: string;
  response: number;
  author: string;
  text: string;
  apollo_id: string;
  source: DocumentCommentSource;
  additional_info: CommentAdditionalInfo;
  document: number;
  user?: ShortUser;
  created_at: string;
  modified_at: string;
}

export interface CommentAdditionalInfo {
  author: string;
  description: string;
  thread_key: string;
  highlight: Highlight;
}

export interface Highlight {
  text: string;
  page_number?: number;
  coordinates: { x: number; y: number }[];
}

export interface DocumentsCharts {
  month: any;
  count: number;
  days: BarChartDataItem[];
  stats_by_partner: { [key: string]: { name: string; value: number } };
}

export interface BarChartDataItem {
  day: string;
  count: number;
}

export enum DocumentCommentSource {
  APOLLO = 'apollo',
  PARTNER = 'partner',
  CLIENT = 'client'
}

export interface ClientDocumentsFiltersValue {
  search: string;
  page: number;
  per_page: number;
  documents_state?: string;
  partner_ids?: number[] | number;
  ordering?: string;
  charts_enabled?: boolean;
  sent_at_after: string;
  sent_at_before: string;
  group?: string;
  directory?: string | number | null;
  documents_archive?: boolean;
  '--selectedTree'?: string[];
}

export interface PartnerDocumentsFiltersValue {
  search: string;
  page: number;
  per_page: number;
  documents_state?: DocumentStatus;
  status?: string;
  ordering?: string;
  last_x_months?: number;
  charts_enabled?: boolean;
  created_at_after: string;
  created_at_before: string;
  directory?: string | number | null;
  documents_archive?: boolean;
  '--selectedTree'?: string;
}

export interface Directory {
  id: number;
  modified_at: string;
  created_at: string;
  name: string;
  is_system: string;
  created_by_client: boolean;
  type: string;
  user: number;
  client_directory: number;
  children: Directory[];
  parent: Omit<Directory, 'children'>;

  page: number;
  per_page: number;
  total_pages: number;
  current_page: number;
}

export interface DirectoryAction {
  name: string;
  parent_id?: number;
}

export enum DirectoryType {
  SIMPLE = 'simple',
  ROOT = 'root',
  SPV = 'spv',
  PRIMITE = 'primite',
  EMISE = 'emise',
  ALTELE = 'altele',
  E_FACTURA = 'e_factura'
}

export enum IzitInvoiceCategoryType {
  RECEIVED = 'primite',
  ISSUED = 'emise',
  OTHERS = 'altele'
}

export enum DocumentFileType {
  PDF = 'pdf',
  XML = 'xml'
}
