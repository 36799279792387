import styled from '@emotion/styled';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { documents, documentsQueries } from 'api';
import { DocumentEditBody } from 'api/documents.types';
import { AxiosError } from 'axios';
import { Flex, Text } from 'components';
import { NotificationContext, ThemeContext } from 'contexts';
import { Edit } from 'icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { getNotificationError } from 'utils';

const StyledMainContent = styled(Text)`
  margin: 0 !important;
  inset-inline-start: 0 !important;
  width: ${(props) =>
    typeof props.editable === 'object' && props.editable?.editing ? '600px !important' : undefined};
`;

const StyledActionContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

interface EditDocumentNameProps {
  id: number;
  allowEdit?: boolean;
}

export const EditDocumentName = ({
  id,
  allowEdit,
  children
}: React.PropsWithChildren<EditDocumentNameProps>) => {
  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<string>();
  const isClickEnter = useRef(false);
  const queryClient = useQueryClient();

  const notifyError = (error: AxiosError) => {
    notification.error({
      message: getNotificationError(error)
    });
  };

  const handleSuccess = () => [
    queryClient.invalidateQueries(documentsQueries.getList().queryKey),
    queryClient.invalidateQueries(documentsQueries.getById(id).queryKey)
  ];

  const editMutation = useMutation((body: DocumentEditBody) => documents.edit(id, body), {
    onError: notifyError,
    onSuccess: handleSuccess
  });

  useEffect(() => {
    if (!value || !isClickEnter.current) return;

    editMutation.mutate({ title: value });

    isClickEnter.current = false;
  }, [value, isClickEnter, editMutation]);

  return (
    <Flex
      onClick={(e) => {
        editing && e.preventDefault();
      }}
    >
      <StyledMainContent
        size="lg"
        key={editing ? children?.toString() : undefined}
        editable={
          allowEdit
            ? {
                editing,
                icon: <></>,
                onStart: () => setEditing(true),
                onChange: (value) => {
                  setValue(value);
                  setEditing(false);
                },
                tooltip: false,
                onEnd: () => (isClickEnter.current = true)
              }
            : false
        }
      >
        {children}
      </StyledMainContent>

      {allowEdit && (
        <StyledActionContent>
          <Edit
            color={currentTheme['grey-white']}
            onClick={(e) => {
              e.preventDefault();
              setEditing(true);
            }}
          />
        </StyledActionContent>
      )}
    </Flex>
  );
};
