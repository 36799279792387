import styled from '@emotion/styled';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Progress, Space, Spin, Tooltip } from 'antd';
import { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import { Link, useParams } from 'react-router-dom';
import { ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
import { useIntersectionObserver } from '@react-hookz/web';

import {
  dateTimeHyphenFormat,
  format,
  getNotificationError,
  getRoute,
  getVisibilityByDocumentStatus,
  hasActiveFilters,
  hasReadOnlyDocuments,
  mimeTypeToDocumentType,
  palette
} from 'utils';
import {
  ActionsDocument,
  ActionsFolders,
  DeleteConfirmModal,
  DocumentStatusLabel,
  DocumentTypeIcon,
  EditDocumentName,
  EditFolderName,
  EmptyField,
  LineChart,
  makeSortingControllerHelper,
  MoveDocumentToFolderModal,
  NoContentPlaceholder,
  NoDataPlaceholder,
  Pagination,
  Put,
  SortingController,
  Table,
  Text
} from 'components';
import { documents, documentsQueries } from 'api';
import { FoldersContext, NotificationContext, ThemeContext } from 'contexts';
import { onFilterChange, useQueryParams } from 'hooks';
import {
  $Object,
  APIDocument,
  ClientDocumentsFiltersValue,
  Directory,
  DocumentStatus
} from 'types';
import { Locked } from 'icons';
import { ChartParams } from 'api/documents.types';
import { AxiosError } from 'axios';

interface DocumentsTableInterface {
  filters: ClientDocumentsFiltersValue;
  queryDocumentParams: ClientDocumentsFiltersValue;
  onFiltersChange: onFilterChange<ClientDocumentsFiltersValue>;
  showFolderColumn?: boolean;
  selectedDocuments: APIDocument[];
  setSelectedDocuments: Dispatch<SetStateAction<APIDocument[]>>;
  downloadingFolder?: number;
  setDownloadingFolder: Dispatch<SetStateAction<number | undefined>>;
  isDownloadLoading: boolean;
  downloadMutate: UseMutateFunction<any, unknown, $Object, unknown>;
  progress: Record<string, number>;
  excludedRows: number[];
  setExcludedRows: Dispatch<SetStateAction<number[]>>;
  selectedAll: boolean;
  setCount: Dispatch<SetStateAction<number>>;
  setData: Dispatch<SetStateAction<APIDocument[]>>;
}

const StyledResponsiveContainerWrapper = styled.div`
  margin: 32px;
  margin-left: 16px;
`;

export const DocumentsTable = ({
  filters,
  queryDocumentParams,
  onFiltersChange,
  showFolderColumn = true,
  selectedDocuments,
  setDownloadingFolder,
  setSelectedDocuments,
  downloadingFolder,
  isDownloadLoading,
  downloadMutate,
  progress,
  excludedRows,
  setExcludedRows,
  selectedAll,
  setCount,
  setData
}: DocumentsTableInterface) => {
  const params = useParams();
  const queryParams = useQueryParams<ChartParams & { documents_state: DocumentStatus }>();
  const { t } = useTranslation();
  const folderName = params['*'];
  const queryClient = useQueryClient();
  const [documentToDelete, setDocumentToDelete] = useState<APIDocument>();
  const lastItemRef = useRef<HTMLDivElement>(null);

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);
  const { fetchNextFolderPage, folderData, isFolderMutateLoading, setSelectedTreeFilter } =
    useContext(FoldersContext);

  const [documentsToMove, setDocumentsToMove] = useState<APIDocument[]>([]);

  const isMultipleFiles = selectedDocuments?.length > 1;
  const selectedDocumentsKeys = selectedDocuments?.map(({ id }) => id);
  const isMoveFileDisabled =
    !queryParams?.partner_ids &&
    !(Array.isArray(queryParams.partner_ids) && queryParams.partner_ids?.length) &&
    isMultipleFiles;

  const { data, isLoading } = useQuery({
    ...documentsQueries.getList({
      ...queryDocumentParams,
      folder_name: folderName,
      group_id: params.group
    }),
    onSuccess: ({ results, count }) => {
      setCount(count);
      setData(results);
      if (selectedDocumentsKeys.length) {
        setSelectedDocuments?.(results?.filter(({ id }) => selectedDocumentsKeys.includes(id)));
      }
    }
  });

  const { data: foldersListData, isLoading: isFoldersLoadingListDefault } = useQuery({
    ...documentsQueries.getDirectoriesList({
      parent: filters.directory,
      per_page: 1000,
      search: filters.search,
      ordering: filters.ordering?.replace('title', 'name')
    }),
    select: (data) => {
      return {
        children: data?.results?.map(({ children, ...rest }) => ({ ...rest, children: undefined }))
      };
    },
    enabled: !!filters.documents_archive && !filters.directory
  });

  const foldersData = useMemo(
    () =>
      filters.directory
        ? {
            children: folderData?.children.map((directory) => ({
              ...directory,
              children: directory.children
            }))
          }
        : foldersListData,
    [filters.directory, folderData, foldersListData]
  );

  const isFoldersLoading =
    ((!filters.directory && isFoldersLoadingListDefault) ||
      (!!filters.directory && isFolderMutateLoading)) &&
    !!filters.documents_archive;

  const { data: chartData, isLoading: isLoadingChart } = useQuery({
    ...documentsQueries.getCharts({
      ...queryDocumentParams,
      folder_name: folderName,
      group_id: params.group
    }),
    enabled: Boolean(filters.charts_enabled)
  });

  const documentsStatsQuery = useQuery(documentsQueries.getClientStats());

  const hasAnyReadOnlyDocumentsSelected = hasReadOnlyDocuments(
    data?.results,
    selectedDocumentsKeys
  );

  const getSortingControllerProps = makeSortingControllerHelper(filters.ordering, (value) =>
    onFiltersChange({ ordering: value })
  );

  const rowSelection: TableRowSelection<APIDocument & Directory> = {
    selectedRowKeys: selectedAll
      ? data?.results.map(({ id }) => id).filter((id) => !excludedRows.includes(id))
      : selectedDocumentsKeys,
    onChange: (_, selectedDocuments) => !selectedAll && setSelectedDocuments?.(selectedDocuments),
    getCheckboxProps: (record) => ({ disabled: 'children' in record }),
    onSelect: (rec, selected) => {
      if (!selectedAll) return;
      setSelectedDocuments([]);
      setExcludedRows((value) =>
        !selected ? [...value, rec.id] : value.filter((id) => id !== rec.id)
      );
    },
    onSelectAll: (selected, __, changeRows) => {
      if (!selectedAll) return;
      setSelectedDocuments([]);
      setExcludedRows((value) =>
        selected
          ? value.filter((id) => !changeRows.find((item) => item.id === id))
          : [...value, ...changeRows.map(({ id }) => id)]
      );
    }
  };

  const invalidateDocumentQueries = () => {
    queryClient.invalidateQueries(documentsQueries.getList().queryKey);
    queryClient.invalidateQueries(documentsQueries.getClientStats().queryKey);
  };

  const deleteMutation = useMutation(() => documents.delete(documentToDelete?.id || 0), {
    onSuccess: () => {
      invalidateDocumentQueries();
      setDocumentToDelete(undefined);

      if (selectedDocuments.find(({ id }) => id === documentToDelete?.id))
        setSelectedDocuments((prev) => prev.filter(({ id }) => id !== documentToDelete?.id));
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: getNotificationError(error)
      });
    }
  });

  const intersection = useIntersectionObserver(lastItemRef.current);

  useEffect(() => {
    if (!intersection?.isIntersecting || !(filters.documents_archive && filters.directory)) return;
    fetchNextFolderPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection?.isIntersecting, filters.documents_archive, filters.directory]);

  const columns = useMemo<ColumnsType<APIDocument & Directory>>(
    () => [
      {
        className: 'absolute',
        render: (_, __, index) =>
          foldersData && index + 1 === foldersData?.children?.length ? (
            <div ref={lastItemRef} />
          ) : null
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('title')}>
            {t('documentName')}
          </SortingController>
        ),
        dataIndex: 'title',
        render: (title, { id, size, name, state, ...record }) => {
          return !('children' in record) ? (
            <Tooltip overlayClassName="sm-tooltip" title={title}>
              <Link
                draggable={false}
                to={getRoute('PartnerDocumentItemPage', { ...queryParams, id })}
              >
                <Space align="center">
                  <DocumentTypeIcon type={mimeTypeToDocumentType(title)} />
                  <div>
                    <EditDocumentName id={id} allowEdit>
                      {title}
                    </EditDocumentName>

                    {Object.hasOwn(progress, title) && state === DocumentStatus.pending ? (
                      <Progress showInfo={false} percent={progress?.[title] || 0} />
                    ) : (
                      <PartnerDescription>{size}</PartnerDescription>
                    )}
                  </div>
                </Space>
              </Link>
            </Tooltip>
          ) : (
            <Link
              draggable={false}
              to={getRoute('DocumentsPage', {
                query: {
                  page: filters.page,
                  per_page: filters.per_page,
                  directory: id,
                  documents_archive: filters.documents_archive,
                  partner_ids: filters.partner_ids,
                  '--selectedTree': setSelectedTreeFilter(id, filters)
                }
              })}
            >
              <EditFolderName id={id} type={record.type} userId={record.user}>
                {name}
              </EditFolderName>
            </Link>
          );
        },
        width: '40%'
      },
      showFolderColumn
        ? {
            title: (
              <SortingController {...getSortingControllerProps('directory')}>
                {t('folder')}
              </SortingController>
            ),
            dataIndex: ['directory', 'client_directory', 'name'],
            render: (folderName: string, record) => (
              <Tooltip overlayClassName="sm-tooltip" title={folderName}>
                <Text size="lg">
                  <Put condition={!!folderName}>
                    <Link
                      to={getRoute('DocumentsPage', {
                        query: {
                          page: filters.page,
                          per_page: filters.per_page,
                          directory: record?.directory?.client_directory?.id
                        }
                      })}
                    >
                      {folderName}
                    </Link>
                  </Put>
                </Text>

                <FolderDownload
                  record={record}
                  mutate={onDownloadFolderClick}
                  isLoading={isDownloadLoading}
                  downloadingFolder={downloadingFolder}
                />
              </Tooltip>
            )
          }
        : {},
      {
        title: (
          <SortingController {...getSortingControllerProps('sent_at')}>
            {t('uploadDate')}
          </SortingController>
        ),
        dataIndex: 'sent_at',
        render: (sentAt) => (
          <EmptyField hidden={!sentAt}>
            <Text
              ellipsis={{
                tooltip: { title: format(sentAt), overlayClassName: 'sm-tooltip' }
              }}
              size="lg"
            >
              {format(sentAt)}
            </Text>

            <PartnerDescription>{format(sentAt, 'HH:mm A')}</PartnerDescription>
          </EmptyField>
        )
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('state')}>
            {t('documentState')}
          </SortingController>
        ),
        dataIndex: 'state',
        render: (state, record) =>
          !('children' in record) ? <DocumentStatusLabel unfilled status={state} /> : null
      },
      {
        width: 70,
        dataIndex: 'id',
        render: (_, record) => {
          const { hideActions, hideDelete } = getVisibilityByDocumentStatus(record.state);

          if ('children' in record) {
            return <ActionsFolders folder={record} />;
          }

          return hideActions ? (
            <Locked fontSize={16} fill={currentTheme['grey-60']} />
          ) : (
            <ActionsDocument
              hideDelete={hideDelete}
              hideReplace
              hasReadonlyFile={hasAnyReadOnlyDocumentsSelected}
              document={record}
              setDocumentToDelete={setDocumentToDelete}
              setDocumentToMove={setDocumentsToMove}
              setSelectedDocuments={setSelectedDocuments}
              isMoveDisabled={hasAnyReadOnlyDocumentsSelected || isMoveFileDisabled}
            />
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data?.results,
      isDownloadLoading,
      downloadingFolder,
      isMoveFileDisabled,
      hasAnyReadOnlyDocumentsSelected,
      t,
      foldersData,
      progress
    ]
  );

  const PartnerDescription = styled.div`
    font-size: 0.75rem;
    color: ${currentTheme['grey-60']};
  `;

  const onDownloadFolderClick = ({ id, directory }: APIDocument) => {
    setDownloadingFolder(id);
    downloadMutate({
      ids: directory?.client_directory?.id,
      name: `SmartPortal-export-${dayjs().format(dateTimeHyphenFormat)}.zip`
    });
  };

  const showFoldersData =
    filters.page === 1 &&
    !(filters.documents_state && !filters.directory) &&
    filters.documents_archive;

  return (
    <NoContentPlaceholder
      show={
        !data?.results.length &&
        !isLoading &&
        !hasActiveFilters(filters) &&
        documentsStatsQuery.data?.total === 0 &&
        !foldersData?.children?.length &&
        isFoldersLoading
      }
      placeholder={
        <NoDataPlaceholder
          mainIcon="file-black-outline"
          leftIcon="document-text"
          onLeftClick={() => null}
          rightIcon="document-pdf"
          onRightClick={() => null}
          description={<div dangerouslySetInnerHTML={{ __html: t('noPartnerDocuments') }} />}
        />
      }
    >
      {filters.charts_enabled && (
        <Spin spinning={isLoadingChart}>
          <StyledResponsiveContainerWrapper>
            <ResponsiveContainer width="100%" height="100%" minHeight={400}>
              <LineChart
                staticLabel={t('documents')}
                data={chartData || []}
                partners={
                  typeof filters.partner_ids === 'number'
                    ? [filters.partner_ids]
                    : filters.partner_ids
                }
              />
            </ResponsiveContainer>
          </StyledResponsiveContainerWrapper>
        </Spin>
      )}

      <MoveDocumentToFolderModal
        open={!!documentsToMove?.length}
        title={
          isMultipleFiles
            ? t('moveMultipleFilesTitle', { count: selectedDocuments?.length })
            : t('moveFile')
        }
        onCancel={() => setDocumentsToMove([])}
        onMoveFolder={isMultipleFiles ? setSelectedDocuments : setDocumentsToMove}
        documentsToMove={isMultipleFiles ? selectedDocuments : documentsToMove}
      />

      <DeleteConfirmModal
        mutation={deleteMutation}
        open={!!documentToDelete}
        title={t('deleteDocument')}
        onCancel={() => setDocumentToDelete(undefined)}
      >
        <Text size="md">{t('approveDocumentDelete')}</Text>

        <Text size="md" strong>
          {documentToDelete?.title}
        </Text>
      </DeleteConfirmModal>

      <Table
        loading={isLoading || isFoldersLoading}
        dataSource={
          [
            ...(showFoldersData ? foldersData?.children || [] : []),
            ...(data?.results || [])
          ] as (APIDocument & Directory)[]
        }
        columns={columns}
        rowSelection={rowSelection}
        rowKey="id"
        pagination={false}
        rowClassName={(_, index) =>
          foldersData && index + 1 === foldersData?.children?.length ? 'last-item' : ''
        }
      />

      <Pagination
        pageSize={filters.per_page}
        current={filters.page}
        total={data?.count}
        onChange={onFiltersChange}
      />
    </NoContentPlaceholder>
  );
};

const FolderDownload = ({
  record,
  mutate,
  isLoading,
  downloadingFolder
}: {
  record: APIDocument;
  mutate: (record: APIDocument) => void;
  isLoading: boolean;
  downloadingFolder?: number;
}) => {
  const { t } = useTranslation();

  const { currentTheme } = useContext(ThemeContext);

  const [isHover, setIsHover] = useState(false);

  return (
    <Text
      size="sm"
      color={palette['grey-60']}
      onMouseEnter={() => {
        if (record?.directory?.client_directory?.id) {
          setIsHover(true);
        }
      }}
      onMouseLeave={() => {
        if (record?.directory?.client_directory?.id) {
          setIsHover(false);
        }
      }}
    >
      {isHover || (isLoading && downloadingFolder === record.id) ? (
        <Text
          size="sm"
          onClick={() => mutate(record)}
          style={{ color: currentTheme['purple-100'], cursor: 'pointer' }}
        >
          {t('downloadFolder')}
          {isLoading && <Spin spinning={true} size="small" style={{ marginLeft: 5 }} />}
        </Text>
      ) : (
        <Put condition={!!record.folder_files_count}>
          {record.folder_files_count} {t('documents')}
        </Put>
      )}
    </Text>
  );
};
