import { Button, Form, Space, Input as AntdInput } from 'antd';
import { useTranslation } from 'react-i18next';

import { DocumentRejectBody } from 'api/documents.types';
import { Modal, ModalProps } from './Modal';

interface Props extends ModalProps {
  onCancel?: () => void;
  onSuccess?: (body: DocumentRejectBody) => void;
}

export const RemarkModal = ({ confirmLoading, onSuccess, onCancel, ...props }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <Modal
      title={t('reject')}
      onCancel={onCancel}
      footer={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button type="link" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form="remark-document-form"
            loading={confirmLoading}
          >
            {t('reject')}
          </Button>
        </Space>
      }
      {...props}
    >
      <Form form={form} layout="vertical" id="remark-document-form" onFinish={onSuccess}>
        <Form.Item label={t('comments')} name="remark">
          <AntdInput.TextArea autoSize={{ minRows: 4 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
