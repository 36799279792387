import { useContext, useRef } from 'react';
import { Button, Checkbox, Form, Input as AntInput, Space, Typography } from 'antd';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams, Location, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { save } from 'react-cookies';
import { Input, PublicLayout, STInput, Text } from 'components';
import { Description } from '../components';
import { NotificationContext, ThemeContext } from 'contexts';
import { session, usersApi } from 'api';
import { useForm } from 'antd/lib/form/Form';
import { axiosHeadersUpdater } from 'api/axios';
import { DocumentStatus, UserType } from 'types';
import {
  comparePaths,
  getFilters,
  getNotificationError,
  getRoute,
  Locale,
  setFilters
} from 'utils';
import { getAlignedNotificationStyles } from '../utils';
import { useUpdateLanguage } from 'hooks';
import { LoginInterface } from 'api/session.types';
import { PARTNER_DOCUMENTS_FILTERS } from 'app-constants';

export const LogInPage = () => {
  const [form] = useForm();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();

  const formContainerRef = useRef<HTMLDivElement>(null);

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const { languageUpdate } = useUpdateLanguage();

  const { mutate, isLoading } = useMutation(
    (data: LoginInterface) => session.login(data, getUserTypeByRoute(location), params.domain),
    {
      onSuccess: (res) => {
        if (res.access) {
          save('--access-token--', res.access, { path: '/' });
          save('--refresh-token--', res.refresh, { path: '/' });

          axiosHeadersUpdater();

          queryClient.fetchQuery(usersApi.getLoggedUserData().queryKey);

          languageUpdate(i18n.language as Locale);

          if (res.role === UserType.partner) {
            const storedFilters = getFilters();
            setFilters({
              ...storedFilters,
              [PARTNER_DOCUMENTS_FILTERS]: {
                documents_state: DocumentStatus.pendingApproval,
                page: 1
              }
            });
            return navigate(getRoute('PartnerDocumentsPage', params));
          }

          if (res.role === UserType.admin) {
            return navigate(getRoute('ClientsPage'));
          }

          navigate(getRoute('DocumentsPage'));
        }
      },
      onError: (error: AxiosError) => {
        notification.error({
          message: getNotificationError(error),
          placement: 'bottomLeft',
          style: formContainerRef.current
            ? getAlignedNotificationStyles(formContainerRef.current)
            : undefined
        });
      }
    }
  );

  return (
    <PublicLayout rightSide={<Description />}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Space ref={formContainerRef} direction="vertical" style={{ width: '20rem', gap: 0 }}>
          <Typography.Title style={{ margin: 0 }}>{t('profileAccess')}</Typography.Title>

          <Form
            form={form}
            style={{ marginTop: '2rem' }}
            onFinish={(values) => {
              mutate({ ...values, email: values?.email });
            }}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t('pleaseInsertEmail')
                }
              ]}
            >
              <Input prefix="user" placeholder="Username" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: t('pleaseInsertPassword') }]}
            >
              <STInput prefix="lock" Input={AntInput.Password} placeholder={t('password')} />
            </Form.Item>

            <Space
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '24px'
              }}
            >
              <Checkbox>{t('keelMeLogged')}</Checkbox>

              <Link to={getRoute(getUserSubmitEmailRouteName(location), params)}>
                <Text size="lg" style={{ color: currentTheme['purple-75'] }}>
                  {t('forgotPasswordQuestion')}
                </Text>
              </Link>
            </Space>

            <Button type="primary" style={{ width: '100%' }} htmlType="submit" loading={isLoading}>
              {t('logIn')}
            </Button>
          </Form>
        </Space>
      </div>
    </PublicLayout>
  );
};

const getUserTypeByRoute = (location: Location) => {
  if (comparePaths(getRoute('ClientLogInPage'), location.pathname)) return UserType.client;

  if (comparePaths(getRoute('AdminLogInPage'), location.pathname)) return UserType.admin;

  if (comparePaths(getRoute('PartnerLogInPage'), location.pathname)) return UserType.partner;

  return UserType.client;
};

const getUserSubmitEmailRouteName = (location: Location) => {
  if (comparePaths(getRoute('ClientLogInPage'), location.pathname)) return 'ClientSubmitEmailPage';

  if (comparePaths(getRoute('AdminLogInPage'), location.pathname)) return 'AdminSubmitEmailPage';

  if (comparePaths(getRoute('PartnerLogInPage'), location.pathname))
    return 'PartnerSubmitEmailPage';

  return 'ClientSubmitEmailPage';
};
