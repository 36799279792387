/** @jsxImportSource @emotion/react */
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';
import type { MenuProps } from 'antd';
import { DelegateDocumentStatus, Dropdown, Flex, RemarkModal } from 'components';
import { NotificationContext, ThemeContext } from 'contexts';
import { Check, CloseBig, Info2 } from 'icons';
import { documents, documentsQueries } from 'api';
import { AxiosError } from 'axios';
import { getNotificationError } from 'utils';
import { useModalState } from 'hooks';
import { DelegatedDocument, DelegatedDocumentType, DocumentRejectBody } from 'api/documents.types';
import { stylesInit } from './DelegateDocumentFooter.styles';

interface DelegateDocumentFooterProps {
  delegatedDocument: DelegatedDocument[];
  isClient?: boolean;
}

export const DelegateDocumentFooter = ({
  delegatedDocument,
  isClient
}: DelegateDocumentFooterProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);
  const rejectModalState = useModalState();

  const styles = stylesInit(currentTheme);

  const actualDocument = delegatedDocument?.[delegatedDocument?.length - 1];
  const isDisabledActions = actualDocument?.type !== DelegatedDocumentType.WAITING;

  const notifyError = (error: AxiosError) => {
    notification.error({
      message: getNotificationError(error)
    });
  };

  const handleSuccess = () => {
    queryClient.invalidateQueries(
      documentsQueries.getDelegatedDocumentById(Number(actualDocument?.id)).queryKey
    );

    rejectModalState.close();
  };

  const { mutate: rejectMutation, isLoading: isRejectLoading } = useMutation(
    (body?: DocumentRejectBody) =>
      documents.rejectDelegatedDocument(Number(actualDocument?.id), body),
    {
      onError: notifyError,
      onSuccess: handleSuccess
    }
  );

  const { mutate: confirmMutation, isLoading: isConfirmLoading } = useMutation(
    () => documents.approveDelegatedDocument(Number(actualDocument?.id)),
    {
      onError: notifyError,
      onSuccess: handleSuccess
    }
  );

  const dropdownDocument = isClient
    ? delegatedDocument?.[0]?.document?.delegated_approvals
    : delegatedDocument;

  const items: MenuProps['items'] = useMemo(
    () =>
      dropdownDocument?.map((document) => ({
        key: document.id,
        label: <DelegateDocumentStatus document={document} />
      })),
    [dropdownDocument]
  );

  return (
    <Flex
      gap="8px"
      justify="space-between"
      align="center"
      width={isClient ? '100%' : 'calc(100% - 241px)'}
      css={isClient ? styles.ClientContainer : styles.Container}
    >
      <Flex align="center" gap={24}>
        <DelegateDocumentStatus document={actualDocument} />

        <Dropdown
          overlayStyle={{ paddingBottom: '15px', paddingRight: '30px' }}
          menu={{ items }}
          placement="topRight"
        >
          <Button shape="circle" type="text" icon={<Info2 />} />
        </Dropdown>
      </Flex>

      {isClient && !isDisabledActions && (
        <Flex gap={8} justify="end">
          <Button
            type="link"
            danger
            css={styles.Button}
            icon={<CloseBig />}
            loading={isRejectLoading}
            disabled={isDisabledActions}
            onClick={rejectModalState.open}
          >
            {t('reject')}
          </Button>

          <Button
            css={styles.Button}
            type="primary"
            icon={<Check />}
            disabled={isDisabledActions}
            loading={isConfirmLoading}
            onClick={() => confirmMutation()}
          >
            {t('confirm')}
          </Button>
        </Flex>
      )}

      <RemarkModal
        confirmLoading={isRejectLoading}
        open={rejectModalState.isOpen}
        onCancel={rejectModalState.close}
        onSuccess={rejectMutation}
      />
    </Flex>
  );
};
